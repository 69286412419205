import axios from 'axios';

const base_url = `${process.env.BACKEND_API_BASE_URL}/portal.php`;

export const portalService = {
  create_portal_instance: async (data) => {
    return axios.get(base_url, {
      params: {
        method: 'create_portal_instance',
        ...data
      },
    });
  },
};
